<template>
  <component :is="analyticplanData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No accountingFile found -->
    <b-alert variant="danger" :show="analyticplanData === undefined">
      <h4 class="alert-heading">Erreur lors de chargement du contenu</h4>
      <div class="alert-body">
        Aucun element trouvé avec cet identifiant. Vérifier
        <b-link class="alert-link" :to="{ name: 'analyticplan-list' }">
          Liste des elements
        </b-link>
        pour les autres element.
      </div>
    </b-alert>

    <data-add-tab
      :analyticplan-data="analyticplanData"
      :defaultanalyticplan-data="defaultanalyticplanData"
      class="mt-2"
    />
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BAlert,
  BLink,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import storeModule from "./storeModule";
import DataAddTab from "./FormDataParam.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BAlert,
    BLink,
    BFormCheckbox,
    DataAddTab,
    BButton, 
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
    };
  },
  mounted() {
    if (router.currentRoute.name == "analyticplan-param") {
      this.operationTitle = "Parametrage du ";
      this.operationIcon = "ToolIcon";
    }
  },
  methods: {
    prevPage() {
      router.push({ name: "analyticplan-list" });
    },
    convertBoolToInt(value) {
      if (value == 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    let analyticplanData = ref(null);
    let APP_STORE_MODULE_NAME = "app-analyticplan";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    var defaultanalyticplanData = ref({
      budget: "",
      currency: "", 
      advanced: "",  
    }); 

    store
        .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
          id: router.currentRoute.params.id,
        })
      .then((response) => {

          const data_to_update = response.data; 
        
          data_to_update.budget   = JSON.parse(response.data.budget); 
          data_to_update.currency = JSON.parse(response.data.currency); 
          data_to_update.advanced = JSON.parse(response.data.advanced); 
          analyticplanData.value  = data_to_update;

        })
        .catch((error) => {
          console.log(error);
          analyticplanData.value = undefined;
        }); 

    return {
      analyticplanData,
      defaultanalyticplanData,
    };
  },
};
</script>
